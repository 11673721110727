import axios from 'axios'
import store from '../store'

export default {
    get: (tab,filters) => axios.post('payments/list/'+tab,filters),
    getTotals: (filters) => axios.post('payments/list/count',filters),
    simplePayment: (data) => axios.post('payments/simple/store', data),
    simplePaymentCFDI: (data) => axios.post('payments/simple/cfdi/store', data),
    multiplePayment: (data) => axios.post('payments/multiple/store', data),
    multiplePaymentCFDI: (data) => axios.post('payments/multiple/cfdi/store', data),
    show: (id) => axios.get('payments/'+id+'/show'),
    downloadXML: (id) => axios.get('payments/'+id+'/download-xml').then(function(response){
        if(response.type == "success"){
            window.open(axios.defaults.baseURL+"/s3?file="+response.data, '_blank');
        }
        else{
            store.commit('toast', {color: response.type, message: response.message});
        }
    }),
    downloadPDF: (id) => axios.get('payments/'+id+'/download-pdf').then(function(response){
        if(response.type == "success"){
            window.open(response.data, '_blank');
        }
        else{
            store.commit('toast', {color: response.type, message: response.message});
        }
    }),
    downloadExcel: (filters) => axios.post('payments/list/download-excel',filters).then(function(response){
        if(response.type == "success"){
            window.open(response.data, '_blank');
        }
        else{
            store.commit('toast', {color: response.type, message: response.message});
        }
    }),
    downloadZip: (filters) => axios.post('payments/list/download-zip',filters).then(function(response){
        if(response.type == "success"){
            window.open(response.data, '_blank');
        }
        else{
            store.commit('toast', {color: response.type, message: response.message});
        }
    }),
    downloadCancelationPDF: (id) => axios.get('payments/'+id+'/cancel/download-pdf').then(function(response){
        if(response.type == "success"){
            window.open(response.data, '_blank');
        }
        else{
            store.commit('toast', {color: response.type, message: response.message});
        }
    }),
    store: (data) => axios.post('payments/store', data),
    update: (data) => axios.post('payments/update', data),
    delete: (id) => axios.post('payments/delete', id),
    cancel: (id, data) => axios.post('payments/'+id+'/cancel', data),
    send: (id, data) => axios.post('payments/'+id+'/send/mail', data),
    excel: () => axios.post('payments/excel')
}