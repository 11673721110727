<template>
  <div>
    <CDataTable
      :hover="hover"
      :striped="striped"
      :border="border"
      :small="small"
      :fixed="fixed"
      :items="items"
      :fields="is_new ? fields_with_actions : fields"
      :items-per-page="small ? 10 : 5"
      :dark="dark"
      pagination
      addTableClasses="no-margin"
    >
      <template #folio="{item}"><td class="w200 text-center">{{ item.folio }}</td></template>
      <template #partial="{item}"><td class="w200 text-center">{{ item.partial }}</td></template>
      <template #uuid="{item}"><td class="text-center">{{ item.uuid }}</td></template>
      <template #previous="{item}">
        <td class="w200 text-right">
          <span v-if="item.currency != 'MXN'" style="font-size: 12px;">{{item.currency}}</span> $ {{ item.saldo_ant | currency }}
        </td>
      </template>
      <template #amount="{item}"><td class="w200 text-right"><span v-if="item.currency != 'MXN'" style="font-size: 12px;">{{item.currency}}</span> $ {{ item.amount | currency }}</td></template>
      <template #final="{item}"><td class="w200 text-right"><span v-if="item.currency != 'MXN'" style="font-size: 12px;">{{item.currency}}</span> $ {{ item.saldo_ins | currency }}</td></template>
      <template #actions="{item, index}">
        <td class="table_actions b2" v-if="is_new">
          <CButton color="warning" size="sm" @click="updateModal(item)"><CIcon name="cil-pencil"/></CButton>
          <CButton color="danger" size="sm" @click="deleteModal(item, index)"><CIcon name="cil-trash"/></CButton>
        </td>
      </template>
    </CDataTable>
    <CDataTable
      addTableClasses="no-margin"
      :header="false"
      :hover="hover"
      :striped="striped"
      :border="border"
      :small="small"
      :fixed="fixed"
      :fields="totals_fields"
      :items="totals_items"
    >
      <template #labels="{item}"><th class="w200 text-right" style="text-align: right !important;">{{ item.labels }}</th></template>
      <template #totals="{item, index}">
        <td class="w200 text-right">
          <span v-if="currency != 'MXN'" style="font-size: 12px;">{{currency}}</span> 
          $ {{ (index == 0 ? subtotal : (index == 1 ? transfered_taxes : (index == 2 ? withheld_taxes : total))) | currency }}
        </td>
      </template>
      <template #letters="{item, index}"><td rowspan="4" class="text-center" v-if="index == 0"></td></template>
      <template #final="{item}"><td class="w200 text-right"></td></template>
      <template #actions="{item, index}">
        <td class="table_actions b2" v-if="is_new"></td>
      </template>
    </CDataTable>
    <InvoiceModal ref="invoiceModal" @updateInvoice="updateProcess"></InvoiceModal>
    <DeleteModal ref="deleteModal" @delete="deleteProcess"></DeleteModal>
  </div>
</template>

<script>

import InvoiceModal from './modals/InvoiceModal.vue'
import DeleteModal from '../global/DeleteModal.vue'
import store from '../../store'

export default {
  name: 'ConceptsTable',
  components: { InvoiceModal, DeleteModal },
  props: {
    is_new: true,
    items: Array,
    caption: {
      type: String,
      default: 'Table'
    },
    hover: Boolean,
    striped: Boolean,
    border: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean,
    letters: {
      type: String,
      default: ''
    },
    subtotal: 0,
    total: 0,
    currency: '',
    exchange_rate: 0
  },
  data() {
    return {
      fields: [
        {key: 'folio', label: 'Folio'},
        {key: 'uuid', label: 'UUID'},
        {key: 'partial', label: 'No. Parcialidad'},
        {key: 'previous', label: 'Saldo Anterior'},
        {key: 'amount', label: 'Monto Pagado'},
        {key: 'final', label: 'Saldo Final'},
      ],
      fields_with_actions: [
        {key: 'folio', label: 'Folio'},
        {key: 'uuid', label: 'UUID'},
        {key: 'partial', label: 'No. Parcialidad'},
        {key: 'previous', label: 'Saldo Anterior'},
        {key: 'amount', label: 'Monto Pagado'},
        {key: 'final', label: 'Saldo Final'},
        {key: 'actions', label: 'Acciones', class: 'actions' },
      ],
      toasts: [],
      item: false,
      totals_items: [
        {letters: '', labels: 'Total', totals: '0'}
      ],
      totals_fields: [
        {key: 'letters', label: ''},
        {key: 'labels', label: ''},
        {key: 'totals', label: ''},
        {key: 'actions', label: ''},
        {key: 'final', label: ''}
      ]
    }
  },
  mounted: async function() {
    
  },
  methods: {
    updateModal (item) {
      this.$refs.invoiceModal.editInvoiceModal(item);
      this.item = item;
    },
    deleteModal (item, index) {
      this.$refs.deleteModal.show("Eliminar Concepto", index, item.description);
      this.item = item;
    },
    async updateProcess(data) {
      data.amount = data.amount.replaceAll(',', '');

      this.item.folio = data.folio;
      this.item.uuid = data.uuid;
      this.item.amount = data.amount;
      
      this.item.saldo_ins = this.item.saldo_ant - data.amount;
      this.item.final = this.item.saldo_ant - data.amount;

      this.$emit("refresh");
    },
    async deleteProcess(index) {
      this.$emit("deleteConcept", index);
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    },
  },
  computed: {
    has_access () {
      return store.state.stamps <= 0 || store.state.deadline_days <= 0 ? false : true;
    },
  }
}
</script>
