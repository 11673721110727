<template>
    <CModal :title="title" :color="colorModal" :show.sync="confirmModal">
        <p style="font-weight: bold;">{{content}}</p>
        <br>
        {{confirm}}
        <br /><br />
        <template #header>
          <h5 class="modal-title">{{title}}</h5>
          <CButtonClose @click="confirmModal = false" class="text-white"/>
        </template>
        <template #footer>
          <CButton @click="confirmModal = false" color="secondary">Cancelar</CButton>
          <CButton @click="confirmationModal" color="danger">Aceptar</CButton>
        </template>
    </CModal>
</template>

<script>

import store from '../../store'

export default {
  name: 'ConfirmModal',
  props: {

  },
  data () {
    return {
      confirmModal: false,
      colorModal: 'warning',
      title: "Información",
      content: '',
      confirm: ''
    }
  },
  methods: {
    show (color, title, content, confirm) {
      this.confirmModal = true;
      this.colorModal = color;
      this.title = title;
      this.content = content;
      this.confirm = confirm;
    },
    confirmationModal () {
      this.confirmModal = false;
      this.loading();
      this.$emit("confirm");
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>