<template>
  <div>
    <CRow>
      <CCol sm="4">
       <CCard class="invoice-card">
        <CCardHeader color="info">
          Emisor
          <div class="card-header-actions">
            
          </div>
        </CCardHeader>
        <CCardBody>
          <b>{{invoice.emisor.rfc}}</b>
          <br />
          {{invoice.emisor.name}}
          <br />
          <br />
          {{invoice.emisor.regimen.code}} - {{invoice.emisor.regimen.name}}
        </CCardBody>
      </CCard>
      </CCol>
      <CCol sm="4">
       <CCard class="invoice-card">
        <CCardHeader color="info">
          <slot name="header">
            Receptor
            <div class="card-header-actions" v-if="is_new">
              <CButton class="btn btn-light" size="sm" @click="receptorModal(invoice.receptor)">Editar</CButton>
            </div>
          </slot>
        </CCardHeader>
        <CCardBody>
          <b>{{invoice.receptor.rfc}}</b>
          <br />
          {{invoice.receptor.name}} <span v-if="invoice.receptor.rfc == 'XEXX010101000' && invoice.receptor.identifier != undefined && invoice.receptor.identifier != ''">[Reg. Id: {{invoice.receptor.identifier}}]</span>
          <br />
          <br />
          {{invoice.receptor.uso_cfdi.code}} - {{invoice.receptor.uso_cfdi.name}}
        </CCardBody>
        <ReceptorModal ref="receptorModal" type="P" @updateReceptor="updateReceptor"></ReceptorModal>
      </CCard>
      </CCol>
      <CCol sm="4">
       <CCard class="invoice-card">
        <CCardHeader color="info">
          <slot name="header">
            Información del Pago
            <div class="card-header-actions" v-if="is_new">
              <CButton class="btn btn-light" size="sm" @click="informationModal(invoice.information)">Editar</CButton>
            </div>
          </slot>
        </CCardHeader>
        <CCardBody>
          <div class="card-header-actions text-right">
              <b>Fecha de Pago: </b>{{invoice.information.date | date}}
              <br /><br /><br />
              <b>Moneda: </b>{{invoice.information.currency.code}} <span v-show="invoice.information.currency.code != 'MXN'">({{invoice.information.currency.exchange_rate}})</span>
          </div>
          <b>Folio: </b>{{invoice.information.serial}}{{invoice.information.folio}}
          <br />
          <b>Folio Fiscal: </b>{{invoice.information.uuid}}
          <br />
          <b>Forma de Pago: </b>{{invoice.information.payment_form.code}} - {{invoice.information.payment_form.name}}
          <span v-show="invoice.information.no_operation">
            <br />
            <b>No. Operación: </b>{{invoice.information.no_operation}}
          </span>
        </CCardBody>
        <InformationModal ref="informationModal" type="payment" @updateInformation="updateInformation"></InformationModal>
      </CCard>
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="12">
       <CCard class="invoice-concepts">
        <CCardHeader>
          <slot name="header">
            Conceptos
            <div class="card-header-actions">
              <CButton v-if="is_new" class="btn btn-outline-success" size="sm" v-show="!has_relations" @click="enableRelations()">CFDI Relacionados</CButton>
              <CButton v-if="is_new" class="btn btn-outline-info" size="sm" @click="addInvoiceModal(invoice.receptor.rfc, invoice.information.currency.code)">Agregar Factura</CButton>
              <span v-if="!is_new && invoice.canceled">CANCELADA</span>
            </div>
          </slot>
        </CCardHeader>
        <CCardBody>
          <ConceptsTable
            :items="concepts"
            :subtotal="subtotal"
            :transfered_taxes="transfered_taxes"
            :withheld_taxes="withheld_taxes"
            :total="total"
            :letters="letters"
            :is_new="is_new"
            hover
            striped
            border
            small
            fixed
            caption="Conceptos"
            @refresh="refreshConcepts"
            @deleteConcept="deleteConcept"
          />
          <InvoiceModal ref="invoiceModal" @addInvoice="addInvoice"></InvoiceModal>
        </CCardBody>
      </CCard>
      </CCol>
    </CRow>
    <CRow v-if="has_relations">
      <CCol sm="12">
       <CCard>
        <CCardHeader>
          <slot name="header">
            CFDIs Relacionados
            <div class="card-header-actions" v-if="is_new">
              <CButton class="btn btn-outline-dark" size="sm" @click="disableRelations()" v-show="has_relations && relations.length == 0">Cerrar</CButton>
              <CButton class="btn btn-outline-info" size="sm" @click="addRelationModal(invoice.receptor.rfc, relations)">Agregar CFDI</CButton>
            </div>
          </slot>
        </CCardHeader>
        <CCardBody>
          <RelationsTable
            :items="relations"
            :is_new="is_new"
            :relation_type="relation_type"
            hover
            striped
            border
            small
            fixed
            caption="CFDIs Relacionados"
            @refresh="refreshRelations"
            @deleteRelation="deleteRelation"
          />

          <RelationModal ref="relationModal" type="P" :relation_type="relation_type" @addRelation="addRelation" :relationDisabled="relationDisabled"></RelationModal>
        </CCardBody>
      </CCard>
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="12" v-if="has_access">
        <CCard>
          <CCardBody class="text-left">
            <CButton class="btn btn-outline-danger" size="sm" @click="cancelInvoice()" v-if="!is_new && !invoice.canceled">Cancelar</CButton>
            <!-- <CButton class="btn btn-outline-danger" size="sm" @click="downloadCancelationPDF()" v-if="!is_new && invoice.canceled && invoice.cfdi_id > 0">Acuse de Cancelación</CButton> -->
            <CButton class="btn btn-outline-dark" size="sm" @click="clearInvoice()" v-if="is_new">Limpiar</CButton>
            <div class="card-header-actions">
              <CButton color="success" size="sm" @click="downloadXML()" v-if="!is_new && invoice.cfdi_id > 0">XML</CButton>
              <CButton color="danger" size="sm" @click="downloadPDF()" v-if="!is_new">PDF</CButton>
              <CButton color="info" size="sm" @click="sendInvoiceModal()" v-if="!is_new && invoice.cfdi_id > 0">Enviar por Correo</CButton>
              <CButton color="info" size="sm" @click="storeInvoice(0)" v-if="is_new">Emitir Pago</CButton>
              <CButton color="info" size="sm" @click="confirmCFDI()" v-if="is_new">Emitir Pago con CFDI</CButton>
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CancelationModal ref="cancelModal" @delete="cancelProcess"></CancelationModal>
    <EmailModal ref="emailModal" @send="sendInvoice"></EmailModal>
    <ConfirmModal ref="confirmModal" @confirm="storeInvoice(1)"></ConfirmModal>
  </div>
</template>

<script>
import ConceptsTable from '../payments/ConceptsTable.vue'
import RelationsTable from '../invoices/RelationsTable.vue'
import ReceptorModal from '../invoices/modals/ReceptorModal.vue'
import InformationModal from '../invoices/modals/InformationModal.vue'
import EmailModal from '../invoices/modals/EmailModal.vue'
import CancelationModal from '../invoices/modals/CancelationModal.vue'
import InvoiceModal from '../payments/modals/InvoiceModal.vue'
import RelationModal from '../invoices/modals/RelationModal.vue'
import ConfirmModal from '../global/ConfirmModal.vue'
import ws from '../../services/payments';
import invoices_ws from '../../services/invoices';
import serials_ws from '../../services/serials';
import products_ws from '../../services/products';
import account_ws from '../../services/account';
import store from '../../store'

import moment from 'moment';

moment.tz.setDefault('America/Mexico_city')

let today = new moment();
today = new moment(today.format("YYYY-MM-DD HH:m:ss"));

export default {
  name: 'PaymentsView',
  components: { ConceptsTable, RelationsTable, ReceptorModal, InformationModal, InvoiceModal, RelationModal, EmailModal, CancelationModal, ConfirmModal },
  props: {
    
  },
  data() {
    return {
      id: 0,
      is_new: true,
      is_pue: false,
      blocked: false,
      has_relations: false,
      products: [],
      invoice: {
        id: 0,
        emisor: {
          rfc: '{rfc}',
          name: '{name}',
          regimen: {
            code: '{regimen_code}',
            name: '{regimen_name}'
          }
        },
        receptor: {
          customer_id: 0,
          rfc: 'XAXX010101000',
          name: 'PÚBLICO EN GENERAL',
          regimen: {
            code: '616',
            name: 'SIN OBLIGACIONES FISCALES'
          },
          uso_cfdi: {
            code: 'S01',
            name: 'SIN EFECTOS FISCALES'
          },
          identifier: '',
          street: '',
          exterior: '',
          interior: '',
          colony: '',
          municipality: '',
          state: 'JAL',
          country: 'MEX',
          zipcode: '46600'
        },
        information: {
          document_type: 'P',
          serial: 'F',
          folio: 0,
          uuid: '',
          date: today,
          payment_form: {
            code: '03',
            name: 'Transferencia'
          },
          payment_method: {
            code: 'PUE',
            name: 'Pago en una sola exhibición'
          },
          no_operation: '',
          currency: {
            code: 'MXN',
            name: 'Peso Mexicano'
          },
          exchange_rate: 1.0000
        },
        canceled: 0
      },
      concepts: [],
      relations: [],
      relationDisabled: false,
      relation_type: "04",
      subtotal: 0,
      transfered_taxes: 0,
      withheld_taxes: 0,
      total: 0,
      letters: '',
      toasts: []
    }
  },
  mounted: async function() {
      this.loading();
      this.id = await this.$route.params.id;

      if(this.id > 0){
        this.is_new = false;
      }

      if(!this.is_new && this.relations.length > 0){
        this.has_relations = true;
      }

      let response1 = await account_ws.getFiscalInfo(); 

      if(response1.type == "success"){
        this.invoice.emisor.rfc = response1.data.rfc.toUpperCase();
        this.invoice.emisor.name = response1.data.fiscal_name.toUpperCase();
        this.invoice.emisor.regimen = response1.data.regimen;
      }

      let response2 = await serials_ws.getDefault("payment"); 

      if(response2.type == "success"){
        let serial = response2.data;

        this.invoice.information.serial = serial.serial;
        this.invoice.information.folio = serial.current;
        this.invoice.information.serial_id = serial.id;
      }

      if(this.id > 0){
        this.showInvoice();
      }
      else{
        //Si los timbres estan vencidos o agotados => Redirecciona a Subscripción
        if(store.state.deadline_days <= 0 || store.state.stamps <= 0){
          //window.location.href = "/#/account/subscription"; return;
        }
        
        this.loaded();
      }

  },
  methods: {
    enableRelations () {
      this.has_relations = true;
    },
    disableRelations () {
      this.has_relations = false;
    },
    receptorModal (data) {
      this.$refs.receptorModal.openModal(data);
    },
    informationModal (data) {
      this.$refs.informationModal.openModal(data);
    },
    addInvoiceModal (rfc, currency) {
      let uuids = [];
      this.concepts.forEach(item => {
          let uuid = item.uuid;

          uuids.push(uuid);
      });

      this.$refs.invoiceModal.addInvoiceModal(rfc, currency, uuids);
    },
    addRelationModal (rfc, data) {
      this.$refs.relationModal.addRelationModal(rfc, data);
    },
    async refreshConcepts (items){
      this.updateTotals();
    },
    async refreshRelations (items){
      this.relations = await items;
    },
    async showInvoice (){
      this.loading();
      let response = await ws.show(this.id);

      this.invoice.id = this.id;

      if(response.type == "success"){
        let data = response.data;

        this.invoice.cfdi_id = response.data.cfdi_id;
        this.invoice.uuid = response.data.uuid;
        this.invoice.canceled = response.data.canceled;

        let emisor_name = "";

        if(data.emisor.nombre != undefined){
          emisor_name = data.emisor.nombre.toUpperCase();
        }

        if(data.emisor.fiscal_name != undefined){
          emisor_name = data.emisor.fiscal_name.toUpperCase();
        }

        this.invoice.emisor.rfc = data.emisor.rfc.toUpperCase();
        this.invoice.emisor.name = emisor_name;
        this.invoice.emisor.regimen.code = data.emisor.regimen.code;
        this.invoice.emisor.regimen.name = data.emisor.regimen.name;

        let receptor_name = "";

        if(data.emisor.nombre != undefined){
          emisor_name = data.emisor.nombre.toUpperCase();
        }

        if(data.emisor.name != undefined){
          emisor_name = data.emisor.name.toUpperCase();
        }

        this.invoice.receptor.rfc = data.receptor.rfc.toUpperCase();
        this.invoice.receptor.name = receptor_name;
        this.invoice.receptor.regimen.code = data.receptor.regimen.code;
        this.invoice.receptor.regimen.name = data.receptor.regimen.name;
        this.invoice.email = response.data.email;

        this.invoice.receptor.uso_cfdi = {
          code : data.uso_cfdi.code,
          name: data.uso_cfdi.name
        }

        this.invoice.information.serial = data.cfdi != undefined ? data.cfdi.serie : "";
        this.invoice.information.folio = data.cfdi != undefined ? data.cfdi.folio : data.folio;
        this.invoice.information.uuid = data.uuid;

        this.invoice.information.payment_form = data.payment_form;
        this.invoice.information.no_operation = data.no_operation;
        
        this.invoice.information.currency = {
          code: data.currency,
          exchange_rate: data.exchange_rate
        };

        this.invoice.information.date = data.date;

        this.subtotal = 0;
        this.transfered_taxes = 0;
        this.withheld_taxes = 0;
        this.total = 0;

        data.invoice_payments.forEach(item => {
            let concept = {
              amount: item.amount,
              folio: item.folio,
              partial: item.partial,
              saldo_ant: item.previous_balance,
              saldo_ins: item.final_balance,
              uuid: item.uuid
            };

            this.subtotal += concept.amount * 1;
            this.total += concept.amount * 1;

            this.concepts.push(concept); 
        });

        this.total = this.subtotal + this.transfered_taxes - this.withheld_taxes;
        this.letters = data.total_letra;

        this.loaded();
      }
    },
    async updateReceptor(data) {
      this.invoice.receptor.customer_id = data.customer_id;
      this.invoice.receptor.rfc = data.rfc;
      this.invoice.receptor.name = data.name;
      
      this.invoice.receptor.uso_cfdi.code = data.uso_cfdi_code;
      this.invoice.receptor.uso_cfdi.name = data.uso_cfdi_name;

      this.invoice.receptor.regimen.code = data.regimen_code;
      this.invoice.receptor.regimen.name = data.regimen_name;

      this.invoice.receptor.identifier = data.identifier;

      this.invoice.receptor.street = data.street;
      this.invoice.receptor.exterior = data.exterior;
      this.invoice.receptor.interior = data.interior;
      this.invoice.receptor.colony = data.colony;
      this.invoice.receptor.municipality = data.municipality;
      this.invoice.receptor.state = data.state;
      this.invoice.receptor.country = data.country;
      this.invoice.receptor.zipcode = data.zipcode;

      let concept = {};

      this.concepts.push(concept);

      this.clearInvoice();
    },
    async updateInformation(data) {
      this.invoice.information.serial = data.serial;
      this.invoice.information.folio = data.folio;
      this.invoice.information.serial_id = data.serial_id;
      
      this.invoice.information.payment_form.code = data.payment_form_code;
      this.invoice.information.payment_form.name = data.payment_form_name;
      this.invoice.information.payment_method.code = data.payment_method_code;
      this.invoice.information.payment_method.name = data.payment_method_name;
      this.invoice.information.no_operation = data.no_operation;

      this.invoice.information.currency.code = data.currency_code;
      this.invoice.information.currency.name = data.currency_name;
      this.invoice.information.currency.exchange_rate = data.exchange_rate;

      let date = moment(data.date);

      this.invoice.information.date = date;
    },
    async addInvoice(data) {
      data.amount = data.amount.replaceAll(',', '');

      let concept = {
        invoice_id: data.id,
        amount: data.amount,
        currency: data.currency,
        exchange_rate: data.exchange_rate,
        partial: data.partial, 
        previous: data.balance,
        saldo_ant: data.balance,
        saldo_ins: data.balance - data.amount,
        final: data.balance - data.amount,
        folio: data.folio, 
        uuid: data.uuid, 
      };

      this.concepts.push(concept);

      this.updateTotals();
    },
    async addRelation(data) {
      this.relations.push(data.uuid);
      this.relation_type = data.relation_type;

      this.relationDisabled = true;
    },
    deleteConcept(index) {
      this.concepts.splice(index, 1);

      this.updateTotals();
    },
    deleteRelation(index) {
      this.relations.splice(index, 1);

      if(this.relations.length == 0){
        this.relationDisabled = false;
      }
    },
    clearInvoice() {
      this.concepts = [];

      this.updateTotals();
    },
    updateTotals(){
      this.subtotal = 0;
      this.total = 0;

      this.is_pue = false;

      this.concepts.forEach(async item => {
          this.subtotal += item.amount * 1;
          await this.getInvoice(item.invoice_id);
      });

      this.total = this.subtotal;
      this.letters = ""; 
    },
    async getInvoice(id){
      let response = await invoices_ws.show(id);

      if(response && response.type == "success"){
        let data = response.data;

        if(data.payment_method.code == "PUE"){
          this.is_pue = true;
        }
      }

      return false;
    },
    async confirmCFDI () {
      if(this.is_pue){
        this.$refs.confirmModal.show("warning","Confirmación de Pago con CFDI", "Alguna de las facturas que deseas pagar tiene método de pago PUE y no es obligatorio emitir un complemento de pago.", "¿Estás seguro que deseas emitir un complemento de pago?");
      }
      else{
        this.storeInvoice(1);
      }
    },
    async storeInvoice(cfdi) {
      if(this.blocked){
        return;
      }

      this.blocked = true;

      this.loading();

      let exchange_rate = this.invoice.information.exchange_rate;

      if(this.invoice.information.currency.exchange_rate !== undefined){
        exchange_rate = this.invoice.information.currency.exchange_rate;
      }
      else{
        if(exchange_rate === undefined){
          exchange_rate = 1.000;
        }
      }
      
      let data = {
        customer_id: this.invoice.receptor.customer_id,
        receptor: {
          rfc: this.invoice.receptor.rfc,
          name: this.invoice.receptor.name,
          zipcode: this.invoice.receptor.zipcode,
          regimen: this.invoice.receptor.regimen.code,
          identifier: this.invoice.receptor.identifier,
          street: this.invoice.receptor.street,
          exterior: this.invoice.receptor.exterior,
          interior: this.invoice.receptor.interior,
          colony: this.invoice.receptor.colony,
          municipality: this.invoice.receptor.municipality,
          state: this.invoice.receptor.state,
          country: this.invoice.receptor.country
        },
        uso_cfdi: this.invoice.receptor.uso_cfdi.code,
        version: 4.0,
        date: this.invoice.information.date.format("YYYY-MM-DD HH:m:ss"),
        serial_id: this.invoice.information.serial_id,
        currency: this.invoice.information.currency,
        exchange_rate: exchange_rate,
        payment_method: this.invoice.information.payment_method,
        payment_form: this.invoice.information.payment_form,
        no_operation: this.invoice.information.no_operation,
        amount: this.total,
        concepts: this.concepts,
        relations: this.relations,
        relation_type: this.has_relations ? this.relation_type : "04" 
      }

      let response = false;
      if(cfdi){
        response = await ws.multiplePaymentCFDI(data);
      }
      else{
        response = await ws.multiplePayment(data);
      }
      
      if(response && response.type == "success"){
        this.$emit("refresh", response.data );

        window.location.href = "/#/payments/"+response.data.id+"/view";
      }
      else{
        this.loaded();
      }

      this.showToast(response.type, response.message);
      this.blocked = false;
    },
    async sendInvoiceModal() {
      this.$refs.emailModal.show(this.invoice, 'P');
    },
    async sendInvoice(invoice, data) {
      this.loading();
      let response = await ws.send(invoice.id, data);

      this.showToast(response.type, response.message);
      this.loaded();
    },
    async cancelInvoice() {
      this.$refs.cancelModal.show("Cancelar Pago", this.invoice);
    },
    async cancelProcess(id, data) {
      this.loading();
      let response = await ws.cancel(id, data);

      if(response.type == "success"){
        this.clearInvoice();
        this.showInvoice();
      }

      this.showToast(response.type, response.message);
      this.loaded();
    },
    async downloadXML(){
      ws.downloadXML(this.id);
    },
    async downloadPDF(){
      ws.downloadPDF(this.id);
    },
    async previewPDF(){
      let data = {
        customer_id: this.invoice.receptor.customer_id,
        receptor: {
          rfc: this.invoice.receptor.rfc,
          name: this.invoice.receptor.name,
          zipcode: this.invoice.receptor.zipcode,
          regimen: this.invoice.receptor.regimen.code,
          street: this.invoice.receptor.street,
          exterior: this.invoice.receptor.exterior,
          interior: this.invoice.receptor.interior,
          colony: this.invoice.receptor.colony,
          municipality: this.invoice.receptor.municipality,
          state: this.invoice.receptor.state
        },
        uso_cfdi: this.invoice.receptor.uso_cfdi.code,
        version: 4.0,
        date: this.invoice.information.date.format("YYYY-MM-DD HH:m:ss"),
        serial_id: this.invoice.information.serial_id,
        currency: this.invoice.information.currency,
        exchange_rate: this.invoice.information.exchange_rate,
        payment_method: this.invoice.information.payment_method,
        payment_form: this.invoice.information.payment_form,
        no_operation: this.invoice.information.no_operation,
        concepts: this.concepts,
        relations: this.relations,
        relation_type: this.relation_type
      }

      let response = await ws.preview(data);

      if(response.type != "success"){
        this.showToast(response.type, response.message);
      }
    },
    async downloadCancelationPDF(){
      ws.downloadCancelationPDF(this.id);
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  },
  computed: {
    has_access () {
      return store.state.stamps <= 0 || store.state.deadline_days <= 0 ? false : true;
    },
  }
}
</script>
