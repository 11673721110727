<template>
    <CModal
      :title="title"
      :color="color"
      size="lg"
      :show.sync="invoiceModal"
    >
      <CRow>
        <CCol md="12">
          <CForm>
            <CRow>
              <CCol md="8">
                <CSelect
                  label="Factura:"
                  :isValid="checkIfValid('uuid')"
                  :value.sync="$v.form.uuid.$model"
                  :options="invoicesOptions"
                  @change="changeInvoice"
                />
              </CCol>
              <CCol md="4">
                <CInput
                  label="Monto (pagado):"
                  :lazy="false"
                  :isValid="checkIfValid('amount')"
                  :value.sync="$v.form.amount.$model"
                  placeholder=""
                  invalidFeedback="Este campo es requerido y debe ser valor mayor que cero."
                />
              </CCol>
            </CRow>
          </CForm>
        </CCol>
      </CRow>
        <template #footer>
          <CButton @click="closeModal" color="secondary">Cancelar</CButton>
          <CButton @click="store" :color="color">Aceptar</CButton>
        </template>
    </CModal>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required, minLength, maxLength, integer, minValue, email} from "vuelidate/lib/validators"
import { DatePicker } from 'v-calendar'
import serials_ws from '../../../services/serials';
import general_ws from '../../../services/general';
import invoices_ws from '../../../services/invoices';

import moment from 'moment';

const today = new moment();
const today2 = new moment();

export default { 
  name: 'InvoiceModal',
  props: {

  },
  components: {
    'v-date-picker': DatePicker
  },
  data () {
    return {
      invoiceModal: false,
      title: "Agregar Factura", 
      color: "info",
      isEdit: false,
      form: {},
      submitted: false,
      invoices: [],
      invoicesOptions: [],
      taxes: [],
      taxesOptions: [],
      default_unit: false,
      default_tax: false
    }
  },
  mounted: async function() {
    let response1 = await invoices_ws.getUUIDsWithCurrencyBalance("XAXX010101000", "MXN"); 

    if(response1.type == "success"){
      this.invoices = response1.data;

      this.invoicesOptions = await this.$parseSelectOptionsOrdered(this.invoices, "uuid", "folio:uuid:balance_amount");
    }

    this.taxesOptions = await this.$parseSelectOptionsOrdered(this.taxes, "code", "name");
  },
  methods: {
    async changeInvoice () {
      let invoice = await this.$findElementByUUID(this.invoices, this.form.uuid);

      this.form.id = invoice.id;
      this.form.folio = invoice.folio;
      this.form.uuid = invoice.uuid;
      this.form.balance = invoice.balance;
      this.form.partial = invoice.partial;
      this.form.currency = invoice.currency;
      this.form.exchange_rate = invoice.exchange_rate;
    },
    async addInvoiceModal (rfc, currency, uuids) {
      this.invoiceModal = true;
      this.color = "info";
      this.title = 'Agregar Factura';
      this.isEdit = false;
      this.submitted = false;

      let response1 = false;
      
      if(currency != 'ALL'){
        response1 = await invoices_ws.getUUIDsWithCurrencyBalance(rfc, currency, {uuids: uuids}); 
      }
      else{
        response1 = await invoices_ws.getUUIDsWithBalance(rfc, {uuids: uuids}); 
      }

      if(response1.type == "success"){
        this.invoices = response1.data;

        this.invoicesOptions = await this.$parseSelectOptions(this.invoices, "uuid", "folio:uuid:balance_amount");
      }

      this.form = {
        amount: '',
        id: this.invoices[0].id,
        uuid: this.invoices[0].uuid,
        folio: this.invoices[0].folio,
        balance: this.invoices[0].balance,
        partial: this.invoices[0].partial,
        currency: this.invoices[0].currency,
        exchange_rate: this.invoices[0].exchange_rate
      };
    },
    editInvoiceModal (data) {
      this.invoiceModal = true;
      this.color = "info";
      this.title = 'Editar Factura';
      this.isEdit = true;
      this.submitted = false;

      this.form = {
        id: data.id,
        amount: data.amount,
        uuid: data.uuid,
        folio: data.folio,
        balance: data.balance,
        partial: data.partial
      };
    },
    async store () {
      this.$v.$touch();

      if (this.isValid) {
        this.submitted = true;
        
        if(!this.isEdit){
          this.$emit("addInvoice", this.form);
        }
        else{
          this.$emit("updateInvoice", this.form);
        }

        this.closeModal();
      }
    },
    closeModal () {
      this.invoiceModal = false;

      this.submitted = false
      this.$v.$reset()
    },
    checkIfValid (fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      
      return !(field.$invalid)
    },
  },
  computed: {
    formString () { return JSON.stringify(this.form, null, 4) },
    isValid () { return !this.$v.form.$invalid },
    isDirty () { return this.$v.form.$anyDirty },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      amount: {
        required, 
        minValue: minValue(0.01)
      },
      uuid: {
        required
      },
      folio: {},
    }
  },
}
</script>